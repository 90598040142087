import React from "react"

import "./thankyou.css"

const Thankyou = props => {
  return (
    <>
      <div className="content content-thankyou">
         
        <h1 className="title title-thankyou">
          <span className="one">Gracias por </span>
          <span className="two">usar tu voz </span>
          <span className="three">para ayudar </span>
          <span className="four">a formar el </span>
          <span className="five">mañana. </span>
        </h1>
        <div
          className="transition-control"
          onTransitionEnd={() => {
            props.setSection("thankyou social-media")
          }}
        >
          Transition Control Element
        </div>
      </div>
    </>
  )
}

export default Thankyou
