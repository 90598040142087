import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
// import YouTube from "react-youtube"

import ReactPlayer from "react-player/youtube"

import "./the-community-video.css"

const TheCommunityVideo = props => {
  const imageData = useStaticQuery(graphql`
    query {
      stickerClimateThresholdVideo: file(
        relativePath: { eq: "stickers/mx/sticker-climate-threshold.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      stickerInternalWorkplace: file(
        relativePath: { eq: "stickers/mx/sticker-internal-workplace.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      stickerMarketplace: file(
        relativePath: { eq: "stickers/mx/sticker-marketplace.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      stickerCommunitiesVideo: file(
        relativePath: { eq: "stickers/mx/sticker-communities.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  const [showVideo, setShowVideo] = useState(false)
  // const opts = {
  //   height: "100%",
  //   width: "100%",
  //   playerVars: {
  //     origin: props.origin,
  //     // autoplay: 1,
  //     rel: 0,
  //   },
  // }
  // const _onReady = event => {
  //   // access to player in all event handlers via event.target
  //   event.target.playVideo()
  // }
  // const origin = "http://stage.beheard.mars.com"
  return (
    <>
      <div className={`the-community-video-hldr ${props.setCommunityVideo}`}>
        <div className="video-parent-hldr">
          <div className="video-container">
            <div className="iframe-hldr ratio ratio-16x9">
              {showVideo && (
                <ReactPlayer
                  url="https://youtu.be/tiIwufsg7a8"
                  width="100%"
                  height="100%"
                  controls={true}
                  stopOnUnmount={true}
                />
                // <YouTube videoId="JLSZ1kDt9OI" opts={opts} onReady={_onReady} />
              )}
            </div>

            {!showVideo && (
              <div
                className="video-play-btn"
                onClick={() => setShowVideo(true)}
              >
                play
              </div>
            )}
          </div>
          <a
            href="https://www.mars.com/fullpotential"
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-mars-green rounded-0"
            id="learn-more-es"
          >
            Conoce más
          </a>
        </div>

        <figure className="mb-0 sticker video-sticker-climate-threshold">
          <Img
            fluid={imageData.stickerClimateThresholdVideo.childImageSharp.fluid}
            alt=""
          />
        </figure>
        <figure className="mb-0 sticker video-sticker-internal-workplace">
          <Img
            fluid={imageData.stickerInternalWorkplace.childImageSharp.fluid}
            alt=""
          />
        </figure>
        <figure className="mb-0 sticker video-sticker-communities">
          <Img
            fluid={imageData.stickerCommunitiesVideo.childImageSharp.fluid}
            alt=""
          />
        </figure>
        <figure className="mb-0 sticker video-sticker-marketplace">
          <Img
            fluid={imageData.stickerMarketplace.childImageSharp.fluid}
            alt=""
          />
        </figure>
        <div
          className="transition-control"
          onTransitionEnd={() => props.setScrollControl(true)}
        >
          Transition Control Element
        </div>
      </div>
    </>
  )
}

export default TheCommunityVideo
