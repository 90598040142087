import React from "react"

import "./record-your-voice.css"

const RecordYourVoiceContent = props => {
  return (
    <>
      <div className="content content-record-your-voice">
        <h4 className="title title-record-your-voice">
          Sé parte de nuestro estudio global y dinos:
        </h4>

        <h1 className="title title-record-your-voice">
          <span className="one">¿Qué necesita </span>
          <span className="two">cambiar para que </span>
          <span className="three">más mujeres </span>
          <span className="four d-lg-none">puedan alcanzar </span>
          <span className="five d-lg-none">su máximo potencial? </span>
          <span className="four d-none d-lg-block">puedan alcanzar </span>
          <span className="five d-none d-lg-block">su máximo </span>
          <span className="six d-none d-lg-block">potencial? </span>
        </h1>
        <a
          href="https://survey.phonic.ai/5fae0acd31623c02be2ca836?lang=es"
          className="btn btn-add-voice btn-record-your-voice"
          id="survey-es"
          target="_blank"
          rel="noopener noreferrer"
        >
          Suma tu voz ahora
        </a>
        <div
          className="transition-control"
          onTransitionEnd={() => props.setScrollControl(true)}
        >
          Transition Control Element
        </div>
      </div>
    </>
  )
}

export default RecordYourVoiceContent
