import React from "react"

import "./the-community.css"

const TheCommunityLeftContent = props => (
  <>
    <div className="content content-community">
      <h3 className="title title-community">
        Las voces de las mujeres son poderosas y necesitan ser escuchadas. Por
        eso les estamos pidiendo a las mujeres, sin importar en dónde se
        encuentren, que compartan sus voces para que juntas podamos formar un
        mejor mañana.
      </h3>

      <div className="d-lg-none">
        <div className="content-timeline-community">
          <p className="para">
            <span className="bullet"></span>
            Suma tu voz escribiendo o grabando tu respuesta.
          </p>
          <p className="para">
            <span className="bullet"></span>
            Tus respuestas e ideas, junto con las de otras personas de alrededor
            del mundo, serán incluidas en un estudio global.
          </p>
          <p className="para">
            <span className="bullet"></span>
            El estudio informará sobre acciones, herramientas e iniciativas
            diseñadas para ayudar a más mujeres a alcanzar su máximo potencial.
          </p>
        </div>
      </div>
      <div
        className="transition-control"
        onTransitionEnd={() => props.setScrollControl(true)}
      >
        Transition Control Element
      </div>
    </div>
  </>
)

export default TheCommunityLeftContent
