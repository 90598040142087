import React from "react"

const TheCommunityRightContent = () => (
  <>
    <div className="content content-community right">
      <div className="content-timeline-community">
        <p className="para">
          <span className="bullet"></span>
          Suma tu voz escribiendo o grabando tu respuesta.
        </p>
        <p className="para">
          <span className="bullet"></span>
          Tus respuestas e ideas, junto con las de otras personas de alrededor
          del mundo, serán incluidas en un estudio global.
        </p>
        <p className="para">
          <span className="bullet"></span>
          El estudio informará sobre acciones, herramientas e iniciativas
          diseñadas para ayudar a más mujeres a alcanzar su máximo potencial.
        </p>
      </div>
    </div>
  </>
)

export default TheCommunityRightContent
